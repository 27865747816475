import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class UIPopover extends Controller {
  static targets = ["content", "wrapper", "trigger"];

  connect() {
    this.popperInstance = createPopper(this.triggerTarget, this.contentTarget, {
      placement: 'bottom-start', // Align to the bottom-right
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 0], // Adjust as needed
          },
        },
      ],
    });

    this.setupHoverEvents();
  }

  disconnect() {
    this.teardownHoverEvents();
  }

  show() {
    this.contentTarget.classList.remove("hidden");
    this.contentTarget.dataset.state = "open";
    this.popperInstance.update();
  }

  hide() {
    this.contentTarget.classList.add("hidden");
    this.contentTarget.dataset.state = "closed";
  }

  setupHoverEvents() {
    this.triggerTarget.addEventListener("mouseenter", this.show.bind(this));
    this.triggerTarget.addEventListener("mouseleave", this.hide.bind(this));
    this.contentTarget.addEventListener("mouseenter", this.show.bind(this));
    this.contentTarget.addEventListener("mouseleave", this.hide.bind(this));
  }

  teardownHoverEvents() {
    this.triggerTarget.removeEventListener("mouseenter", this.show.bind(this));
    this.triggerTarget.removeEventListener("mouseleave", this.hide.bind(this));
    this.contentTarget.removeEventListener("mouseenter", this.show.bind(this));
    this.contentTarget.removeEventListener("mouseleave", this.hide.bind(this));
  }
}
